<script setup lang='ts'>
</script>

<template>
  <div class="header" @click="$emit('click')">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  width: 100%;
  padding-bottom: map-get($spaces, s2);
  margin-bottom: map-get($spaces, s1);
  border-bottom: 1px solid map-get($lightSurface, level2);
}
</style>