<script setup lang="ts"></script>

<template>
  <div
    class="element"
    @click="$emit('click')"
  >
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.element {
  display: flex;
  flex-direction: column;
  background-color: map-get($lightSurface, level0);
  border-radius: map-get($rays, s4);
  margin-bottom: map-get($spaces, s3);
  padding: map-get($spaces, s4);
  @media (max-width: map-get($breakPoints, xs)) {
    padding: map-get($spaces, s2);
  }
  &:last-child {
    margin-bottom: 0;
  }
}
</style>
